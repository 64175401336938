import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ViewLogDetails } from '../../../components/ViewLogDetails';
import { IntegrationLogService } from '../../../service/IntegrationLogService';

export const ListIntegrationLogs = () => {
    const dt = useRef(null);
    const history = useHistory();

    const [integrationLogs, setIntegrationLogs] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [logDetailsView, setLogDetailsView] = useState(false);

    const integrationLogService = new IntegrationLogService();

    useEffect(() => {
        integrationLogService.getIntegrationLogs().then(data => setIntegrationLogs(data));
    }, []);


    const header = (
        <div className="table-header">
            <h5 className="p-mb-1">Gerenciamento de Logs - CRM x Senior</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter((e.target as HTMLInputElement).value)} placeholder="Pesquisar..." />
            </span>
        </div>
    );

    const titleBodyTemplate = (rowData) => {
        const request = JSON.parse(rowData['request'])

        return (
            request['title']
        );
    }

    const clientBodyTemplate = (rowData) => {
        const request = JSON.parse(rowData['request'])

        return (
            request['company']['name']
        );
    }

    const statusBodyTemplate = (rowData) => {
        const response = JSON.parse(rowData['response'])

        return (
            response['mensagemRetorno']
        );
    }

    const getStatus = ( rowData: JSON ) => {
        const response = JSON.parse( rowData['response'] )

        let gridParStatus = "Parcelas a serem integradas não informadas.";
        let gridProStatus = "Produtos a serem integrados não informados.";
        let gridSerStatus = "Serviços a serem integrados não informados.";

        const gridPar = response[ 'respostaPedido' ][0][ 'gridPar' ]
        const gridPro = response[ 'respostaPedido' ][0][ 'gridPro' ]        
        const gridSer = response[ 'respostaPedido' ][0][ 'gridSer' ]
        
        /**
         * Verificação de todos as parcelas, produtos e serviços dentro da 
         * proposta.
         */
        let i;
        if ( gridPar ) {
            for ( i = 0; i < Object.keys( gridPar ).length; i++ ) {
                if ( gridPar[i].retorno != "OK" ) {
                    gridParStatus = "Falha de integração."
                    break
                }

                gridParStatus = "Integradas com sucesso."
            }
        }

        if ( gridPro ) {
            for ( i = 0; i < Object.keys( gridPro ).length; i++ ) {
                if ( gridPro[i].retorno != "OK" ) {
                    gridProStatus = "Falha de integração."
                    break
                }

                gridProStatus = "Integrados com sucesso."
            }
        }

        if ( gridSer ) {
            for ( i = 0; i < Object.keys( gridSer ).length; i++ ) {
                if ( gridSer[i].retorno != "OK" ) {
                    gridSerStatus = "Falha de integração."
                    break
                }

                gridSerStatus = "Integrados com sucesso."
            }
        }

        /**
         * Definição da característica do status de cada processo.
         */
        let gridParButton = "p-button-success p-m-2"
        let gridProButton = "p-button-success p-m-2"
        let gridSerButton = "p-button-success p-m-2"


        if ( gridParStatus == "Falha de integração." ) {
            gridParButton = "p-button-danger p-m-2"
        } else if ( gridParStatus == "Parcelas a serem integradas não informadas." ) {
            gridParButton = "p-button-secondary p-m-2"
        }

        if ( gridProStatus == "Falha de integração." ) {
            gridProButton = "p-button-danger p-m-2"
        } else if ( gridProStatus == "Produtos a serem integrados não informados." ) {
            gridProButton = "p-button-secondary p-m-2"
        }

        if ( gridSerStatus == "Falha de integração." ) {
            gridSerButton = "p-button-danger p-m-2"
        } else if ( gridSerStatus == "Serviços a serem integrados não informados." ) {
            gridSerButton = "p-button-secondary p-m-2"
        }

        if ( gridParStatus == "Parcelas a serem integradas não informadas." ) {
            if ( gridProStatus == "Produtos a serem integrados não informados." ) {
                if ( gridSerStatus == "Serviços a serem integrados não informados." ) {
                    return (
                        <div>
                            <Button icon="pi pi-credit-card" className={ gridParButton } aria-label={ gridParStatus } tooltip="Integração de parcelas" disabled />
                            <Button icon="pi pi-shopping-cart" className={ gridProButton } aria-label={ gridProStatus } tooltip="Integração de produtos" disabled />
                            <Button icon="pi pi-cog" className={ gridSerButton } aria-label={ gridSerStatus } tooltip="Integração de serviços" disabled />
                        </div>            
                    );
                } else {
                    return (
                        <div>
                            <Button icon="pi pi-credit-card" className={ gridParButton } aria-label={ gridParStatus } tooltip="Integração de parcelas" disabled />
                            <Button icon="pi pi-shopping-cart" className={ gridProButton } aria-label={ gridProStatus } tooltip="Integração de produtos" disabled />
                            <Button icon="pi pi-cog" className={ gridSerButton } aria-label={ gridSerStatus } tooltip="Integração de serviços" />
                        </div>            
                    );
                }
            } else {
                if ( gridSerStatus == "Serviços a serem integrados não informados." ) {
                    return (
                        <div>
                            <Button icon="pi pi-credit-card" className={ gridParButton } aria-label={ gridParStatus } tooltip="Integração de parcelas" disabled />
                            <Button icon="pi pi-shopping-cart" className={ gridProButton } aria-label={ gridProStatus } tooltip="Integração de produtos" />
                            <Button icon="pi pi-cog" className={ gridSerButton } aria-label={ gridSerStatus } tooltip="Integração de serviços" disabled />
                        </div>            
                    );
                } else {
                    return (
                        <div>
                            <Button icon="pi pi-credit-card" className={ gridParButton } aria-label={ gridParStatus } tooltip="Integração de parcelas" disabled />
                            <Button icon="pi pi-shopping-cart" className={ gridProButton } aria-label={ gridProStatus } tooltip="Integração de produtos" />
                            <Button icon="pi pi-cog" className={ gridSerButton } aria-label={ gridSerStatus } tooltip="Integração de serviços" />
                        </div>            
                    );
                }
            }
        } else {
            if ( gridProStatus == "Produtos a serem integrados não informados." ) {
                if ( gridSerStatus == "Serviços a serem integrados não informados." ) {
                    return (
                        <div>
                            <Button icon="pi pi-credit-card" className={ gridParButton } aria-label={ gridParStatus } tooltip="Integração de parcelas" />
                            <Button icon="pi pi-shopping-cart" className={ gridProButton } aria-label={ gridProStatus } tooltip="Integração de produtos" disabled />
                            <Button icon="pi pi-cog" className={ gridSerButton } aria-label={ gridSerStatus } tooltip="Integração de serviços" disabled />
                        </div>            
                    );
                } else {
                    return (
                        <div>
                            <Button icon="pi pi-credit-card" className={ gridParButton } aria-label={ gridParStatus } tooltip="Integração de parcelas" />
                            <Button icon="pi pi-shopping-cart" className={ gridProButton } aria-label={ gridProStatus } tooltip="Integração de produtos" disabled />
                            <Button icon="pi pi-cog" className={ gridSerButton } aria-label={ gridSerStatus } tooltip="Integração de serviços" />
                        </div>            
                    );
                }
            } else {
                if ( gridSerStatus == "Serviços a serem integrados não informados." ) {
                    return (
                        <div>
                            <Button icon="pi pi-credit-card" className={ gridParButton } aria-label={ gridParStatus } tooltip="Integração de parcelas" />
                            <Button icon="pi pi-shopping-cart" className={ gridProButton } aria-label={ gridProStatus } tooltip="Integração de produtos" />
                            <Button icon="pi pi-cog" className={ gridSerButton } aria-label={ gridSerStatus } tooltip="Integração de serviços" disabled />
                        </div>            
                    );
                } else {
                    return (
                        <div>
                            <Button icon="pi pi-credit-card" className={ gridParButton } aria-label={ gridParStatus } tooltip="Integração de parcelas" />
                            <Button icon="pi pi-shopping-cart" className={ gridProButton } aria-label={ gridProStatus } tooltip="Integração de produtos" />
                            <Button icon="pi pi-cog" className={ gridSerButton } aria-label={ gridSerStatus } tooltip="Integração de serviços" />
                        </div>            
                    );
                }
            }
        }
    }

    const [ log, setLog ] = useState( [] )

    const showLogDetails = (rowData) => {
        setLog( rowData )
        setLogDetailsView(true);
    }

    const hideLogDetails = () => {
        setLogDetailsView(false);
    }

    const buttonAction = ( rowData ) => {
        return(
            <>
                <div className="flex justify-content-center">
                    <Button icon="pi pi-eye" label='Detalhes' className="p-button-secondary" onClick={ () => showLogDetails(rowData)}/>
                </div>        
            </>
        );
    }
    
    return (
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-12">
                <DataTable ref={dt} value={integrationLogs}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
                    globalFilter={globalFilter} 
                    emptyMessage="Nenhum Log Encontrado." 
                    header={header}
                    /**expandedRows={expandedRows} */
                    /**onRowToggle={(e) => setExpandedRows(e.data)} */
                    /**rowExpansionTemplate={rowExpansionTemplate}*/
                >
                    <Column field="integration_id" header="ID CRM" sortable></Column>
                    <Column body={ clientBodyTemplate } header="Cliente"></Column>
                    <Column field="user" header="Usuário" sortable></Column>
                    <Column body={ titleBodyTemplate } header="Titulo"></Column>
                    <Column body={ statusBodyTemplate } header="Retorno"></Column> 
                    <Column body={ getStatus } header="Status"></Column>                  
                    <Column body={ buttonAction } header="Actions"></Column>
                </DataTable>

                <ViewLogDetails
                    visible={ logDetailsView }
                    setDialogVisible={ setLogDetailsView }
                    data={ log }
                />
            </div>
        </div>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom';

import { Delete } from '../../../../components/Delete';
import { useToast } from '../../../../contexts/Toast';
import { ManualService } from '../../../../service/ManualService';
import { Manual } from '../../../../types/Manual';

export const ListManuals = () => {
    const dt = useRef(null);
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [manuals, setManuals] = useState([]);
    const [manual, setManual] =  useState({} as Manual);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteManualAdvisor, setDialogManualAdvisor] = useState(null);

    const manualService = new ManualService();

    useEffect(() => {
        manualService.getManuals().then(data => setManuals(data));
    }, [manual]);

    const handleDelete = async () => {
        const result = await manualService.delete(manual.id);

        if(result.status === 204){
            showSuccess("Boleto removido com sucesso.")
        }else{
            showError("Não foi possível remover o manual.")
        }

        setDialogManualAdvisor(null);
        setManual({} as Manual);
    }

    const deleteBankSlip = (manual) => {
        setManual(manual);
        setDialogManualAdvisor("Você deseja mesmo remover o manual?");
        setDeleteDialog(true);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-mb-1">Gerenciamento de Manuais</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter((e.target as HTMLInputElement).value)} placeholder="Pesquisar..." />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => deleteBankSlip(rowData)}/>
            </div>
        );
    }

    return (
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-3 p-lg-3">
                <Button onClick={() => history.push(`/client-portal/product/manual/create/`)} icon="pi pi-plus" label="Novo Manual" />
            </div>
            <div className="p-col-12 p-lg-12">
                <DataTable ref={dt} value={manuals}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
                    globalFilter={globalFilter} emptyMessage="Nenhum Manual Encontrado." header={header}>
                    <Column field="product.name" header="Produto"></Column>
                    <Column field="name" header="Nome"></Column>
                    <Column field="version" header="Versāo"></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

            <Delete
                visible={deleteDialog}
                setDialogVisible={setDeleteDialog}
                deleteAdvisor={deleteManualAdvisor}
                handleDelete={handleDelete}
            />
        </div>
    );
}

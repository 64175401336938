import { Product } from '../types/Product';
import api from './api';

export class ProductService {
    async getProducts() {
        return api.get('products')
                    .then(res => res.data);
    }

    async getProductByID(id: string) {
        return api.get(`products/${id}`)
                    .then(res => res.data);
    }

    async create(data: Product) {
        return api.post(`products`, data);
    }

    async update(id: string, data: Product) {
        return api.post(`products/${id}`, data);
    }

    async disable(id: string) {
        return api.post(`products/disable/${id}`)
                    .then(res => res.data);
    }
}
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';

import { ProfileService } from '../../../service/ProfileService';
import { getFormErrorMessage } from '../../../utilities/Utils';
import { Client, Address } from '../../../types/Client';
import { CreateAddress } from '../../../components/CreateAddress';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { ClientService } from '../../../service/ClientService';
import { User } from '../../../types/User';
import { Dropdown } from 'primereact/dropdown';
import { UserService } from '../../../service/UserService';
import { useToast } from '../../../contexts/Toast';
import { PersonType } from '../../../types/Person';

export const CreateEditClient = () => {
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [client, setClient] = useState<Client>({} as Client);
    const [clientType, setClientType] = useState(PersonType.legal);
    const [personTypes] = useState([{key: 1, value: "Pessoa Fisica"}, {key: 2, value: "Pessoa Juridica"}]);

    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);

    const [addressDialog, setAddressDialog] = useState(false);
    const [deleteAddressDialog, setDeleteAddressDialog] = useState(false);
    const [address, setAddress] = useState({} as Address);
    const [addresses, setAddresses] = useState([] as Array<Address>);

    const [users, setUsers] = useState([] as Array<User>);

    const clientService = new ClientService();
    const userService = new UserService();

    useEffect(() => {
        async function loadData(){
            if(id && !client.id){
                const data = await clientService.getClientByID(id);
                setClient(data);
                setClientType(data.cnpj ? PersonType.legal : PersonType.phisycal);
                console.log(client)
            }
    
            userService.getUsers().then(data => setUsers(data));
        }

        loadData();
    }, [setClient]);

    const { register, formState: { errors }, handleSubmit, control } = useForm();

    const onSubmit = async (data) => {
        setFormData(data);
        setShowMessage(true);

        let result = null;

        client.person_type_id = 2;
        client.system_id = "1234";

        data = {...data, ...client}

        if(client.id){
            result = await clientService.update(id, data);
        }else{
            result = await clientService.create(data);
        }
        
        if(result.status === 201 || result.status === 200){
            showSuccess("Cliente salvo com sucesso.");
            history.push(`/register/client`)
        }
    };

    const onInputChange = (e, name) => {
        let val = null;

        if(e.target){
            val = (typeof e.target.value === "object" && e.target.value.key) || e.target.value;
        }else if(e.value){
            val = e.value;
        }

        let _client = { ...client };
        _client[`${name}`] = val;

        setClient(_client);
    }

    const addAddress = (_address) => {
        let _addresses = [...addresses];

        _addresses.push(_address);

        setAddresses(_addresses);
    }

    const confirmDeleteAddress = (address) => {
        setAddress(address);
        setDeleteAddressDialog(true);
    }

    const hideDeleteAddressDialog = () => {
        setAddress({} as Address);
        setDeleteAddressDialog(false);
    }

    const deleteAddress = () => {
        let _addresses = addresses.filter(val => val.address !== address.address 
                                                    && val.number !== address.number
                                                    && val.postal_code !== address.postal_code);

        setAddresses(_addresses);
        setAddressDialog(false);
        setAddress({} as Address);
    }
    const isPersonTypeLegal = () => { return clientType === PersonType.legal }

    const leftToolbarAddressTemplate = () => {
        return (
            <React.Fragment>
                <Button type="button" label="Adicionar Endereço" 
                    icon="pi pi-plus" className="p-button-success p-mr-2" onClick={() => setAddressDialog(true)} />
            </React.Fragment>
        )
    }

    const actionBodyTemplateAddress = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteAddress(rowData)} />
            </React.Fragment>
        );
    }

    const deleteContactDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAddressDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteAddress} />
        </React.Fragment>
    );

    return (
        <div>
            <h3>Cadastro/Edição de Usuários</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="person_type">Tipo de Pessoa *</label>
                        <Dropdown name="person_type" value={clientType} options={personTypes} 
                          optionValue="key" optionLabel="value" onChange={(e) => setClientType(e.target.value)}/>
                    </div>
                    {clientType == PersonType.legal && 
                        <div className="p-field p-col">
                            <label htmlFor="cnpj" className={classNames({ 'p-error': errors.cnpj })}>CNPJ *</label>
                            <InputMask mask="99.999.999/9999.99" id="cnpj" name="cnpj" value={client.cnpj} 
                                {...register("cnpj", { required: 'CNPJ é obrigatório.', validate: () => { return isPersonTypeLegal() }})} 
                                onChange={(e) => onInputChange(e, 'cnpj')} className={classNames({ 'p-invalid': errors.cnpj })} />
                        </div>
                    }
                    {clientType == PersonType.phisycal && 
                        <div className="p-field p-col">
                            <label htmlFor="cpf" className={classNames({ 'p-error': errors.cnpj })}>CPF *</label>
                            <InputMask mask="999.999.999-99" id="cpf" name="cpf" value={client.cpf} 
                                {...register("cpf", { required: 'CPF é obrigatório.' , validate: () => { return !isPersonTypeLegal() }})} 
                                onChange={(e) => onInputChange(e, 'cpf')} className={classNames({ 'p-invalid': errors.cpf })} />
                        </div>
                        
                    }
                    {clientType == PersonType.phisycal && 
                        <div className="p-field p-col">
                            <label htmlFor="rg">RG</label>
                            <InputText id="rg" name="rg" value={client.rg} {...register("rg")} onChange={(e) => onInputChange(e, 'rg')} />
                        </div>
                    }
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nome *</label>
                        <InputText id="name" name="name" defaultValue={client.name} {...register("name", { required: 'Nome é obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'name')} className={classNames({ 'p-invalid': errors.name })} />
                        {getFormErrorMessage(errors, 'name')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>E-mail *</label>
                        <InputText id="email" name="email" type="email" defaultValue={client.email}
                            {...register("email", { required: 'Email é obrigatório.',
                                                    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 
                                                    message: 'Informe um e-mail valido. E.g. exemplo@email.com' } })}
                            onChange={(e) => onInputChange(e, 'email')} className={classNames({ 'p-invalid': errors.email })}
                            
                        />
                        {getFormErrorMessage(errors, 'email')}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="phone" className={classNames({ 'p-error': errors.cellphone })}>Telefone</label>
                        <InputMask name="phone" mask="+99 (99) 9999-9999" value={client.phone} onChange={(e) => onInputChange(e, 'phone')} />
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="cellphone">Celular *</label>
                        <InputMask name="cellphone" mask="+99 (99) 99999-9999" value={client.cellphone} 
                            {...register("cellphone", { required: 'Celular é obrigatório.' })} onChange={(e) => onInputChange(e, 'cellphone')} 
                            className={classNames({ 'p-invalid': errors.email })}
                        />
                        {getFormErrorMessage(errors, 'cellphone')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="user_id" className={classNames({ 'p-error': !!errors.user_id })}>Usuário *</label>
                        <Controller name="user_id" defaultValue={client.user_id} control={control} rules={{ required: "Usuário é obrigatório." }} render={({ field }) => (
                            <Dropdown id={field.name} value={field.value} onChange={(e) => field.onChange(e, 'user_id')} options={users} 
                                optionValue="id" optionLabel="name" className={classNames({ 'p-invalid': errors.user_id })} 
                                filter showClear filterBy="name"
                            />
                        )} />
                        {getFormErrorMessage(errors, 'email')}
                    </div>
                </div>

                <div className="p-col-12 p-lg-12">
                    <div className="card">
                        <h5>Endereços</h5>
                        <Toolbar className="p-mb-4" left={leftToolbarAddressTemplate}></Toolbar>

                        <DataTable value={addresses} emptyMessage="Nenhum endereço cadastrado">
                            <Column field="address" header="Endereço"></Column>
                            <Column field="number" header="Número"></Column>
                            <Column field="district" header="Bairro"></Column>
                            <Column field="city" header="Cidade"></Column>
                            <Column field="postal_code" header="CEP"></Column>
                            <Column body={actionBodyTemplateAddress}></Column>
                        </DataTable>
                    </div>
                </div>

                <CreateAddress 
                    visible={addressDialog}
                    setDialogVisible={setAddressDialog}
                    addressData={address}
                    addAddress={addAddress}
                />

                <Dialog visible={deleteAddressDialog} style={{ width: '450px' }} header="Confirmar" 
                        modal footer={deleteContactDialogFooter} onHide={hideDeleteAddressDialog}>
                    <div>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                            {address && <span>Você deseja mesmo deletar o enredeço de <b>{address.address}</b>?</span>}
                        </div>
                    </div>
                </Dialog>

                <Button type="submit" label="Salvar" icon="pi pi-save" className="p-button-primary"/>
            </form>
        </div>
    );
}

import { Lead } from '../types/Lead';
import api from './api';

export class LeadService {
    async getLeads() {
        return api.get('leads')
                    .then(res => res.data);
    }

    async getLeadByID(id: string) {
        return api.get(`leads/${id}`)
                    .then(res => res.data);
    }

    async create(data: Lead) {
        return api.post(`leads`, data);
    }

    async update(id: string, data: Lead) {
        return api.post(`leads/${id}`, data);
    }

    async finish(id: string, data: Lead) {
        return api.post(`leads/${id}/finish`, data);
    }

    async disable(id: string) {
        return api.post(`leads/disable/${id}`)
                    .then(res => res.data);
    }
}
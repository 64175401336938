import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LeadService } from '../../service/LeadService';
import { Lead } from '../../types/Lead';
import { Contact } from '../../types/Contact';
import { CreateContact } from '../../components/CreateContact';
import { ContactService } from '../../service/ContactService';
import { useToast } from '../../contexts/Toast';
import { dateBodyTemplate } from '../../utilities/Utils';
import { FinishLead } from '../../components/FinishLead';

export const Dashboard = () => {
    const dt = useRef(null);
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [leads, setLeads] = useState(Array<Lead>());
    const [lead, setLead ] = useState({} as Lead);
    const [selectedLeads, setSelectedLeads] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);

    const [contactDialog, setContactDialog] = useState(false);
    const [contact] = useState({} as Contact);

    const [finishLeadDialog, setFinishLeadDialog] = useState(false);

    const leadService = new LeadService();
    const contactService = new ContactService();

    useEffect(() => {
        leadService.getLeads().then(data => setLeads(data));
    }, []);

    const header = (
        <div className="table-header">
            <h5 className="p-mb-1">Gerenciamento de Leads</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} placeholder="Pesquisar..." />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => history.push(`/lead/edit/${rowData.id}`)} tooltip="Editar Lead" />
                <Button icon="pi pi-user-plus" className="p-button-rounded p-button-primary p-mr-2" onClick={() => addContact(rowData)} tooltip="Adicionar Contato"/>
                <Button icon="pi pi-check" className="p-button-rounded p-button-warning p-mr-2" onClick={() => finishLead(rowData)} tooltip="Finalizar Lead" />
            </div>
        );
    }

    const addContact = (data: Lead) => {
        setContactDialog(true);
        setLead(data);
    }

    const saveContact = async (newContact: Contact) => {
        newContact.lead_id = lead.id;
        const result = await contactService.create(newContact);

        if(result.status === 200){
            showSuccess("Contato criado com sucesso.")
        }else{
            showError("Nāo foi possível criar o contato.")
        }

        setLead({} as Lead);
        setContactDialog(false);
    }

    const finishLead = (lead: Lead) => {
        setLead(lead);
        setFinishLeadDialog(true);
    }

    const handleFinishLead = async (lead: Lead) => {
        const result = await leadService.finish(lead.id, lead);

        if(result.status === 201){
            showSuccess("Lead finalizada com sucesso.")
        }else{
            showError("Nāo foi possível finalizar a lead.")
        }

        setLead({} as Lead);
        setFinishLeadDialog(false);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Contatos</h5>
                <DataTable value={data.contacts}>
                    <Column field="created_at" header="Data" sortable body={dateBodyTemplate}></Column>
                    <Column field="contact_type_id" header="Tipo de Contato" sortable></Column>
                    <Column field="name" header="Nome" sortable></Column>
                    <Column field="description" header="Descriçāo" sortable></Column>
                </DataTable>
            </div>
        );
    }

    return (
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-6">
                <div className="card summary">
                    <span className="title">Leads</span>
                    <span className="detail">Total de Leads</span>
                    <span className="count visitors">{leads.length}</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-6">
                <div className="card summary">
                    <span className="title">Clientes</span>
                    <span className="detail">Número de clientes atendidos</span>
                    <span className="count purchases">1</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-12">
                <DataTable ref={dt} value={leads} selection={selectedLeads}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
                    globalFilter={globalFilter} emptyMessage="Nenhuma Lead Encontrada." header={header}
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    >
                    <Column expander style={{ width: '3em' }} />
                    <Column field="client.cnpj" header="Cnpj" sortable></Column>
                    <Column field="client.name" header="Cliente" sortable></Column>
                    <Column field="date_next_contact" header="Data Próximo Contato" sortable body={dateBodyTemplate} ></Column>
                    <Column field="origin.name" header="Origem" sortable></Column>
                    <Column field="status.name" header="Status" sortable></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>

                <CreateContact
                    visible={contactDialog}
                    contactData={contact}
                    setDialogVisible={setContactDialog}
                    addContact={saveContact}
                />

                <FinishLead
                    visible={finishLeadDialog}
                    setDialogVisible={setFinishLeadDialog}
                    leadData={lead}
                    finish={handleFinishLead}
                />
            </div>
        </div>
    );
}

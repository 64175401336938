import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Address } from '../types/Client';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { GeneralService } from '../service/GeneralServices';

export const CreateAddress = ({visible, setDialogVisible, addressData, addAddress}) => {
    const [address, setAddress] = useState({} as Address);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const generalService = new GeneralService();

    useEffect(() => {
        if(addressData)
            setAddress(addressData);

        generalService.getCountries().then(data => setCountries(data));
    }, [addressData, setCountries]);

    const onChangeCountry = async(e) => {
        console.log(e)
        const country_id = e.target.value;
        await generalService.getStates(country_id).then(data => setStates(data));

        onInputChange(e, 'country_id');
    }

    const onInputChange = (e, name) => {
        let val = null;

        if(e.target){
            val = (typeof e.target.value === "object" && e.target.value.key) || e.target.value;
        }else if(e.value){
            val = e.value;
        }
        
        let _address = { ...address };
        _address[`${name}`] = val;

        setAddress(_address);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setDialogVisible(false);
    }

    const saveContact = () => {
        setSubmitted(true);

        console.log(address)

        if(address.address && address.district && address.number
            && address.postal_code && address.country_id && address.state_id
        ){
            addAddress(address);
            setAddress({} as Address);
            hideDialog();
        }
    }

    const leadDialogFooter = (
        <>
            <Button type="button" label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button type="button" label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveContact} />
        </>
    );

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header="Novo Endereço" modal className="p-fluid" footer={leadDialogFooter} onHide={hideDialog}>
            <div className="p-field">
                <label htmlFor="address">Endereço</label>
                <InputText id="address" name="address" defaultValue={address.address} onChange={(e) => onInputChange(e, 'address')} required />
                {submitted && !address.address && <small className="p-error">Endereço é obrigatório.</small>}
            </div>
            <div className="p-field">
                <label htmlFor="number">Número</label>
                <InputNumber id="number" name="number" value={address.number} onChange={(e) => onInputChange(e, 'number')} required />
                {submitted && !address.number && <small className="p-error">Número é obrigatório.</small>}
            </div>
            <div className="p-field">
                <label htmlFor="district">Bairro</label>
                <InputText id="district" name="district" defaultValue={address.district} onChange={(e) => onInputChange(e, 'district')} required />
                {submitted && !address.district && <small className="p-error">Bairro é obrigatório.</small>}
            </div>
            <div className="p-field">
                <label htmlFor="country_id">Pais</label>
                <Dropdown id="country_id" name="country_id" value={address.country_id} options={countries} 
                    onChange={(e) => onChangeCountry(e)} required optionValue="id"
                    optionLabel="description" filter showClear filterBy="description"
                />
                {submitted && !address.country_id && <small className="p-error">Pais é obrigatório.</small>}
            </div>
            <div className="p-field">
                <label htmlFor="state_id">Estado</label>
                <Dropdown id="state_id" name="state_id" value={address.state_id} options={states}
                    onChange={(e) => onInputChange(e, 'state_id')} required optionValue="id"
                    optionLabel="description" filter showClear filterBy="description"
                />
                {submitted && !address.state_id && <small className="p-error">Estado é obrigatório.</small>}
            </div>
            <div className="p-field">
                <label htmlFor="city">Cidade</label>
                <InputText id="city" name="city" defaultValue={address.city} onChange={(e) => onInputChange(e, 'city')} required />
                {submitted && !address.city && <small className="p-error">Cidade é obrigatório.</small>}
            </div>
            <div className="p-field">
                <label htmlFor="postal_code">CEP</label>
                <InputText id="postal_code" name="postal_code" defaultValue={address.postal_code} onChange={(e) => onInputChange(e, 'postal_code')} required />
                {submitted && !address.postal_code && <small className="p-error">CEP é obrigatório.</small>}
            </div>
        </Dialog>
    );
}

import api from './api';

export class GeneralService {
    async getCountries() {
        return api.get('countries').then(res => res.data);
    }

    async getStates(country_id: string) {
        return api.get(`states`, {params: {country_id: country_id}}).then(res => res.data);
    }
}
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Contact } from '../types/Contact';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { ContactType } from '../types/ContactType';
import { ContactTypeService } from '../service/ContactTypeService';

export const CreateContact = ({visible, setDialogVisible, contactData, addContact}) => {
    const [contact, setContact] = useState({name: "", description: "", contact_type_id: null} as Contact);
    const [contactTypes, setContactTypes] = useState(Array<ContactType>());
    const [submitted, setSubmitted] = useState(false);

    const contactTypeService = new ContactTypeService();

    useEffect(() => {
        if(contactData)
            setContact(contactData);

        if(contactTypes.length === 0)
            contactTypeService.getContactTypes().then(data => setContactTypes(data));

    }, [contactData]);

    const onInputChange = (e, name) => {
        let val = null;

        if(e.target){
            val = (typeof e.target.value === "object" && e.target.value.key) || e.target.value;
        }else if(e.value){
            val = e.value;
        }
        
        let _contact = { ...contact };
        _contact[`${name}`] = val;

        setContact(_contact);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setDialogVisible(false);
    }

    const saveContact = () => {
        setSubmitted(true);

        if(contact.name.trim() && contact.description.trim() && contact.contact_type_id){
            contact.created_at = new Date().toLocaleString();
            addContact(contact);
            setContact({name: "", description: "", contact_type_id: null} as Contact);
            hideDialog();
        }
    }

    const leadDialogFooter = (
        <>
            <Button type="button" label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button type="button" label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveContact} />
        </>
    );

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header="Novo Contato" modal className="p-fluid" 
                footer={leadDialogFooter} onHide={hideDialog}>
            <div className="p-field">
                <label htmlFor="name">Nome</label>
                <InputText id="name" name="name" defaultValue={contact.name} onChange={(e) => onInputChange(e, 'name')} required />
                {submitted && !contact.name && <small className="p-error">Nome é obrigatório.</small>}
            </div>
            <div className="p-field">
                <label htmlFor="contact_type_id">Tipo de Contato *</label>
                <Dropdown options={contactTypes} value={contact.contact_type_id}  optionValue="id" optionLabel="name" required
                    filter showClear filterBy="name" onChange={(e) => onInputChange(e, 'contact_type_id')} 
                    placeholder="Selecione um Tipo de Contato"
                />
                {submitted && !contact.contact_type_id && <small className="p-error">Produto é obrigatório.</small>}
            </div>
            <div className="p-field">
                <label htmlFor="description">Descrição</label>
                <InputTextarea id="description" name="description" defaultValue={contact.description} 
                    onChange={(e) => onInputChange(e, 'description')} required rows={5}/>
                {submitted && !contact.description && <small className="p-error">Descrição é obrigatório.</small>}
            </div>
        </Dialog>
    );
}

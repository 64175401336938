import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Lead } from '../types/Lead';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { useToast } from '../contexts/Toast';

export const FinishLead = ({visible, setDialogVisible, leadData, finish}) => {
    const { showError } = useToast();

    const [lead, setLead] = useState({} as Lead);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setLead(leadData);
    }, [leadData]);

    const finishLead = async () => {
        setSubmitted(true);

        if(lead.convert_sell === false || (lead.convert_sell === true && lead.jjw_budget_number)){
            await finish(lead);
            hideDialog();
        }else{
            if(lead.convert_sell === null)
                showError("Informe se a lead gerou venda");
            else
                showError("O JJW Budget Number deve ser informado.");
        }
    }

    const hideDialog = () => {
        setDialogVisible(false);
        setSubmitted(false);
    }

    const onInputChange = (e, name) => {
        let val = null;

        if(e.target){
            val = (typeof e.target.value === "object" && e.target.value.key) || e.target.value;
        }else if(e.value){
            val = e.value;
        }
        
        let _lead = { ...lead };
        _lead[`${name}`] = val;

        setLead(_lead);
    }

    const finishLeadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={finishLead} />
        </>
    );

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header="Novo Produto" modal className="p-fluid" footer={finishLeadDialogFooter} onHide={hideDialog}>
            <div className="p-field">
                <label htmlFor="jjw_budget_number">Lead gerou venda?</label>
                <div className="p-field-radiobutton">
                    <RadioButton inputId="yes" value={true} name="convert_sell" 
                        onChange={(e) => onInputChange(e, 'convert_sell')} checked={lead.convert_sell === true} /> 
                    <label htmlFor="yes">Sim</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton inputId="no" value={false} name="convert_sell" 
                        onChange={(e) => onInputChange(e, 'convert_sell')} checked={lead.convert_sell === false} />
                    <label htmlFor="no">Nāo</label>
                </div>
            </div>
            <div className="p-field">
                <label htmlFor="jjw_budget_number">JJW Número de Budget</label>
                <InputNumber value={!lead.jjw_budget_number ? null : lead.jjw_budget_number} useGrouping={false} 
                    onChange={(e) => onInputChange(e, 'jjw_budget_number')} />
            </div>
        </Dialog>
    );
}


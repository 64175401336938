import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';

import { Dashboard } from './pages/dashboard/Dashboard';
import { BreadCrumb } from 'primereact/breadcrumb';
import { CreateEditLead } from './pages/lead/lead/CreateEdit';
import { ListUser } from './pages/register/user/List';
import { CreateEditUser } from './pages/register/user/CreateEdit';
import { ListProfile } from './pages/register/profile/List';
import { CreateEditProfile } from './pages/register/profile/CreateEdit';
import { CreateEditContactTypes } from './pages/lead/contact-types/CreateEdit';
import { ListContactTypes } from './pages/lead/contact-types/List';
import { ListProducts } from './pages/lead/products/List';
import { CreateEditProducts } from './pages/lead/products/CreateEdit';
import { ListOrigins } from './pages/lead/origins/List';
import { CreateEditOrigins } from './pages/lead/origins/CreateEdit';
import { CreateEditStatus } from './pages/lead/status/CreateEdit';
import { ListStatus } from './pages/lead/status/List';
import { ListClient } from './pages/register/client/List';
import { CreateEditClient } from './pages/register/client/CreateEdit';
import { ToastProvider } from './contexts/Toast';
import { ListInvoices } from './pages/client-portal/finance/invoice/List';
import { CreateEditInvoice } from './pages/client-portal/finance/invoice/CreateEdit';
import { ListBankSlips } from './pages/client-portal/finance/bank-slip/List';
import { CreateEditBankSlip } from './pages/client-portal/finance/bank-slip/CreateEdit';
import { ListManuals } from './pages/client-portal/products/manual/List';
import { CreateEditManual } from './pages/client-portal/products/manual/CreateEdit';
import { ListIntegrationLogs } from './pages/integration/logs/List';

const Panel = () => {
    const [layoutMode ] = useState('static');
    const [layoutColorMode ] = useState('dark')
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle ] = useState('outlined');
    const [ripple ] = useState(false);
    const sidebar = useRef();

    const history = useHistory();

    let menuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }

    }, [mobileMenuActive]);

    const onWrapperClick = (event: any) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event: any) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                setOverlayMenuActive(prevState => !prevState);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive(prevState => !prevState);
            }
        }
        else {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const menu = [
        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
        {
            label: 'Cadastros', icon: 'pi pi-fw pi-search',
            items: [
                //{ label: 'Cliente', icon: 'pi pi-fw pi-briefcase', to: '/register/client' },
                //{ label: 'Produtos', icon: 'pi pi-fw pi-ticket', to: '/lead/products' },
                {
                    label: 'Usuários', icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'Cadastro de Usuários', icon: 'pi pi-fw pi-user', to: '/register/user' },
                        { label: 'Perfis de Usuários', icon: 'pi pi-fw pi-sitemap', to: '/register/profile' },
                    ]
                },
            ]
        },
        {
            label: 'Cálculo de vendas', icon: 'pi pi-fw pi-search',
            items: [
                { label: 'Custos de Entrega', icon: 'pi pi-fw pi-briefcase', to: '/register/client' },
                { label: 'Custos Operacionais', icon: 'pi pi-fw pi-briefcase', to: '/register/client' },
                { label: 'Estado x ICMS', icon: 'pi pi-fw pi-briefcase', to: '/register/client' },
                { label: 'Produto x Configuraçāo', icon: 'pi pi-fw pi-briefcase', to: '/register/client' },
                { label: 'Produtos', icon: 'pi pi-fw pi-ticket', to: '/lead/products' },
                {
                    label: 'Usuários', icon: 'pi pi-fw pi-users',
                    items: [
                        { label: 'Cadastro de Usuários', icon: 'pi pi-fw pi-user', to: '/register/user' },
                        { label: 'Perfis de Usuários', icon: 'pi pi-fw pi-sitemap', to: '/register/profile' },
                    ]
                },
            ]
        },
       /* {
            label: 'Painel do Cliente', icon: 'pi pi-fw pi-globe',
            items: [
                {
                    label: 'Financeiro', icon: 'pi pi-fw pi-wallet',
                    items: [
                        { label: 'Notas Fiscais', icon: 'pi pi-fw pi-book', to: '/client-portal/finance/invoice' },
                        { label: 'Boletos', icon: 'pi pi-fw pi-money-bill', to: '/client-portal/finance/bank-slip' },
                    ]
                },
                {
                    label: 'Equipamentos', icon: 'pi pi-fw pi-tablet',
                    items: [
                        { label: 'Manuais', icon: 'pi pi-fw pi-file', to: '/client-portal/product/manual' },
                        { label: 'Manutençāo', icon: 'pi pi-fw pi-calendar', to: '/client-portal/product/maintenance' },
                    ]
                }
            ]
        },*/,,
        {
            label: 'Integracões', icon: 'pi pi-fw pi-globe',
            items: [
                {
                    label: 'Logs', icon: 'pi pi-fw pi-wallet',
                    items: [
                        { label: 'CRM x Senior', icon: 'pi pi-fw pi-book', to: '/integration/logs/crm-senior' },
                    ]
                },
            ]
        },
        { label: 'Relatórios', icon: 'pi pi-fw pi-chart-bar', to: '/report' },
    ];

    const addClass = (element: any, className: any) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element: any, className: any) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }

        return true;
    }

    const logo = layoutColorMode === 'dark' ? '/assets/layout/images/logo-sigamaq-branco.png' : 'assets/layout/images/logo.svg';

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <div>
                <AppTopbar onToggleMenu={onToggleMenu} />

                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                    <div ref={sidebar} className='layout-sidebar layout-sidebar-dark' onClick={onSidebarClick}>
                        <div className="layout-logo" style={{cursor: 'pointer'}} onClick={() => history.push('/')}>
                            <img alt="Logo" src={logo} />
                        </div>
                        <AppProfile />
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                    </div>
                </CSSTransition>

                <div className="layout-main">
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/register/user" exact component={ListUser} />
                    <Route path="/register/user/edit/:id" exact component={CreateEditUser} />
                    <Route path="/register/user/create" exact component={CreateEditUser} />
                    <Route path="/register/profile" exact component={ListProfile} />
                    <Route path="/register/profile/create" exact component={CreateEditProfile} />
                    <Route path="/register/profile/edit/:id" exact component={CreateEditProfile} />
                    <Route path="/register/client" exact component={ListClient} />
                    <Route path="/register/client/create" exact component={CreateEditClient} />
                    <Route path="/register/client/edit/:id" exact component={CreateEditClient} />
                    <Route path="/client-portal/finance/invoice" exact component={ListInvoices} />
                    <Route path="/client-portal/finance/invoice/create" exact component={CreateEditInvoice} />
                    <Route path="/client-portal/finance/invoice/edit/:id" exact component={CreateEditInvoice} />
                    <Route path="/client-portal/finance/bank-slip" exact component={ListBankSlips} />
                    <Route path="/client-portal/finance/bank-slip/create" exact component={CreateEditBankSlip} />
                    <Route path="/client-portal/finance/bank-slip/edit/:id" exact component={CreateEditInvoice} />
                    <Route path="/client-portal/product/manual" exact component={ListManuals} />
                    <Route path="/client-portal/product/manual/create" exact component={CreateEditManual} />
                    <Route path="/client-portal/product/manual/edit/:id" exact component={CreateEditManual} />
                    <Route path="/integration/logs/crm-senior" exact component={ListIntegrationLogs} />
                </div>
                <AppFooter />
            </div>
        </div>
    );

}

export default Panel;

import api from './api';

export class InvoicesService {
    async getInvoices() {
        return api.get('invoices')
                    .then(res => res.data);
    }

    async getInvoiceByID(id: string) {
        return api.get(`invoices/${id}`)
                    .then(res => res.data);
    }

    async create(data: object) {
        return api.post(`invoices`, data);
    }

    async update(id: string, data: object) {
        return api.post(`invoices/${id}`, data);
    }

    async delete(id: string) {
        return api.delete(`invoices/${id}`);
    }

    async attachment(id: string, file: FileList) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('attachment', file[0]);

        return api.patch(`invoices/${id}/attachment`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { getFormErrorMessage } from '../../../../utilities/Utils';
import { BankSlipService } from '../../../../service/BankSlipService';
import { BankSlip } from '../../../../types/BankSlip';
import { useToast } from '../../../../contexts/Toast';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Client } from '../../../../types/Client';
import { ClientService } from '../../../../service/ClientService';
import { Dropdown } from 'primereact/dropdown';
import { FileInput } from '../../../../components/FileInput';

export const CreateEditBankSlip = () => {
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [bankSlip, setBankSlip] = useState<BankSlip>({} as BankSlip);
    const [clients, setClients] = useState([] as Array<Client>);
    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);

    const bankSlipService = new BankSlipService();
    const clientService = new ClientService();

    useEffect(() => {
        async function loadData(){
            if(id && !bankSlip.id){
                bankSlipService.getBankSlipByID(id).then(data => setBankSlip(data));
            }
    
            if(clients.length === 0)
                await clientService.getClients().then(data => setClients(data));
        }
        
        loadData()
    }, [bankSlip]);

    const { register, formState: { errors }, handleSubmit, control } = useForm();

    const onSubmit = async (data) => {
        setFormData(data);
        setShowMessage(true);

        if(!data.attachment){
            showError("Selecione um arquivo!")
        }

        console.log(bankSlip)
        let result = null

        if(bankSlip.id){
            result = await bankSlipService.update(id, bankSlip);
        }else{
            result = await bankSlipService.create(bankSlip);
        }
        
        if(result.status === 201 || result.status === 200){
            const fileResult = await bankSlipService.attachment(result.data.id, data.attachment);

            if(fileResult.status === 204){
                showSuccess("Boleto salvo com sucesso.");
                history.push(`/client-portal/finance/bank-slip`);
            }else{
                showError("Nāo foi possivel salvar o boleto.")
            }
        }else{
            showError("Nāo foi possivel salvar o boleto.")
        }
    };

    const onInputChange = (e, name) => {
        let val = null;

        if(e.target){
            val = (typeof e.target.value === "object" && e.target.value.key) || e.target.value;
        }else if(e.value){
            val = e.value;
        }

        let _bankSlip = { ...bankSlip };
        _bankSlip[`${name}`] = val;

        setBankSlip(_bankSlip);
    }

    return (
        <div>
            <h3>Cadastro/Edição de Boleto</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="person_id" className={classNames({ 'p-error': errors.person_id })}>Cliente *</label>
                        <Controller name="person_id" defaultValue={bankSlip.person_id} control={control} rules={{ required: "Cliente é obrigatório." }} render={({ field }) => (
                            <Dropdown value={field.value} options={clients} optionValue="id" optionLabel="name" 
                                filter showClear filterBy="name" onChange={(e) => {field.onChange(e, 'person_id'); onInputChange(e, field.name);}} 
                                placeholder="Selecione um Cliente" className={classNames({ 'p-invalid': errors.person_id })} autoFocus
                            />
                        )} />
                        {getFormErrorMessage(errors, 'person_id')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="number" className={classNames({ 'p-error': errors.number })}>Número *</label>
                        <InputText id="number" name="number" defaultValue={bankSlip.number} {...register("number", { required: 'Número é obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'number')} className={classNames({ 'p-invalid': errors.number })} />
                        {getFormErrorMessage(errors, 'number')}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="due_date" className={classNames({ 'p-error': errors.due_date })}>Data de Vencimento *</label>
                        <Controller name="due_date" defaultValue={bankSlip.due_date} 
                            rules={{required: "Data de Vencimento"}} control={control} render={({ field }) => (
                            <Calendar id={field.name} value={new Date(field.value)} 
                            onChange={(e) => {field.onChange(e.value); onInputChange(e, field.name);}} 
                            showIcon dateFormat="dd/mm/yy"/>
                        )} />
                        
                        {getFormErrorMessage(errors, 'due_date')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="value" className={classNames({ 'p-error': errors.value })}>Valor *</label>
                        <InputNumber id="value" name="value" value={bankSlip.value ? bankSlip.value : null} mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                            {...register("value", {required: 'Valor é Obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'value')} className={classNames({ 'p-invalid': errors.value })} />
                        {getFormErrorMessage(errors, 'value')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="attachment" className={classNames({ 'p-error': errors.attachment })}>Danfe *</label>
                        <FileInput name="attachment" control={control} setValue={onInputChange} />
                        {getFormErrorMessage(errors, 'attachment')}
                    </div>
                </div>
                <Button type="submit" label="Salvar" icon="pi pi-save" className="p-button-primary"/>
            </form>
        </div>
    );
}

import React, { createContext, useCallback, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';


interface ToastContextData {
  showSuccess(message: string): void;
  showError(message: string | string[]): void;
  showWarning(message: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
    const toast = useRef(null);
    const showSuccess = useCallback(
        (message: string) => {
            toast.current.show({ severity: 'success', detail: message, life: 3000 });
        },
        [toast],
    );

    const showError = useCallback(
        (message: string) => {
            toast.current.show({ severity: 'error', detail: message, life: 3000 });
        },
        [toast],
    );

    const showWarning = useCallback(
        (message: string) => {
            toast.current.show({ severity: 'warn', detail: message, life: 3000 });
        },
        [toast],
    );

    return (
        <ToastContext.Provider value={{ showSuccess, showError, showWarning }}>
            <Toast ref={toast} />
            {children}
        </ToastContext.Provider>
    );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
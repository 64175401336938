import React, { useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

import { useAuth } from './contexts/Auth';

export const AppProfile = () => {

    const auth = useAuth();
    const history = useHistory();

    const [expanded, setExpanded] = useState(false);

    const onClick = (event) => {
        setExpanded(prevState => !prevState);
        event.preventDefault();
    }

    const signOut = () => {
        auth.signOut();
        history.push("/");
    }

    return (
        <div className="layout-profile">
            <div>
                <img src="/assets/layout/images/profile.png" alt="Profile" />
            </div>
            <button className="p-link layout-profile-link" onClick={onClick}>
                <span className="username">{auth.user.name}</span>
                <i className="pi pi-fw pi-cog" />
            </button>
            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ 'layout-profile-expanded': expanded })}>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-user" /><span>Usuário</span></button></li>
                    <li><button type="button" className="p-link" onClick={signOut}><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li>
                </ul>
            </CSSTransition>
        </div>
    );

}

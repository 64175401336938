import api from './api';

export class ContactTypeService {
    async getContactTypes() {
        return api.get('contact-types')
                    .then(res => res.data);
    }

    async getContactTypeByID(id: string) {
        return api.get(`contact-types/${id}`)
                    .then(res => res.data);
    }

    async create(data: object) {
        return api.post(`contact-types`, data);
    }

    async update(id: string, data: object) {
        return api.post(`contact-types/${id}`, data);
    }

    async delete(id: string) {
        return api.delete(`contact-types/${id}`);
    }
}
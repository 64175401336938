import { Button } from 'primereact/button'

export const ParcelaDetails = ( { response }) => {

    const gridPar = response[ 'respostaPedido' ][0][ 'gridPar' ]

    if ( gridPar ) {   
        let i;
        for ( i = 0; i < Object.keys( gridPar ).length; i++ ) {
            if ( gridPar[i].retorno != "OK" ) {
                return (
                    <div>
                        <Button icon="pi pi-times" className="p-button-danger p-button-text p-text-left" label={ gridPar[i].retorno } />
                    </div>
                )
            } else {
                return (
                    <div>
                        <Button icon="pi pi-check" className="p-button-success p-button-text p-text-left" label="Parcelas integradas com sucesso." />
                    </div>
                )
            }
        }
    } else {
        return (
            <div>
                <Button icon="pi pi-exclamation-circle" className="p-button-secondary p-button-text p-text-left" label="Não há parcelas para integrar." />
            </div>
        )
    }   
}
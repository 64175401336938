import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { getFormErrorMessage } from '../../../../utilities/Utils';
import { InvoicesService } from '../../../../service/InvoicesService';
import { Invoice } from '../../../../types/Invoice';
import { useToast } from '../../../../contexts/Toast';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Client } from '../../../../types/Client';
import { ClientService } from '../../../../service/ClientService';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { FileInput } from '../../../../components/FileInput';

export const CreateEditInvoice = () => {
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [invoice, setInvoice] = useState<Invoice>({} as Invoice);
    const [clients, setClients] = useState([] as Array<Client>);
    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);

    const invoiceService = new InvoicesService();
    const clientService = new ClientService();

    useEffect(() => {
        async function loadData(){
            if(id && !invoice.id){
                invoiceService.getInvoiceByID(id).then(data => setInvoice(data));
            }
    
            if(clients.length === 0)
                await clientService.getClients().then(data => setClients(data));
        }
        
        loadData()
    }, [invoice]);

    const { register, formState: { errors }, handleSubmit, control } = useForm();

    const onSubmit = async (data) => {
        setFormData(data);
        setShowMessage(true);

        if(!data.attachment){
            showError("Selecione uma Danfe!")
        }

        console.log(invoice)
        let result = null

        if(invoice.id){
            result = await invoiceService.update(id, invoice);
        }else{
            result = await invoiceService.create(invoice);
        }
        
        if(result.status === 201 || result.status === 200){
            const fileResult = await invoiceService.attachment(result.data.id, data.attachment);

            if(fileResult.status === 204){
                showSuccess("Nota Fiscal salva com sucesso.");
                history.push(`/client-portal/finance/invoice`);
            }
        }
    };

    const onInputChange = (e, name) => {
        let val = null;

        if(e.target){
            val = (typeof e.target.value === "object" && e.target.value.key) || e.target.value;
        }else if(e.value){
            val = e.value;
        }

        let _invoice = { ...invoice };
        _invoice[`${name}`] = val;

        setInvoice(_invoice);
    }

    return (
        <div>
            <h3>Cadastro/Edição de Nota Fiscal</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="person_id" className={classNames({ 'p-error': errors.person_id })}>Cliente *</label>
                        <Controller name="person_id" defaultValue={invoice.person_id} control={control} rules={{ required: "Cliente é obrigatório." }} render={({ field }) => (
                            <Dropdown value={field.value} options={clients} optionValue="id" optionLabel="name" 
                                filter showClear filterBy="name" onChange={(e) => {field.onChange(e, 'person_id'); onInputChange(e, field.name);}} 
                                placeholder="Selecione um Cliente" className={classNames({ 'p-invalid': errors.person_id })} autoFocus
                            />
                        )} />
                        {getFormErrorMessage(errors, 'person_id')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="serie" className={classNames({ 'p-error': errors.serie })}>Série *</label>
                        <InputText id="serie" name="serie" defaultValue={invoice.serie} {...register("serie", { required: 'Série é obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'serie')} className={classNames({ 'p-invalid': errors.serie })} />
                        {getFormErrorMessage(errors, 'serie')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="number" className={classNames({ 'p-error': errors.number })}>Número *</label>
                        <InputText id="number" name="number" defaultValue={invoice.number} {...register("number", { required: 'Número é obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'number')} className={classNames({ 'p-invalid': errors.number })} />
                        {getFormErrorMessage(errors, 'number')}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="issue_date" className={classNames({ 'p-error': errors.issue_date })}>Data de Emissāo *</label>
                        <Controller name="issue_date" defaultValue={invoice.issue_date} 
                            rules={{required: "Data de Emissāo"}} control={control} render={({ field }) => (
                            <Calendar id={field.name} value={new Date(field.value)} 
                            onChange={(e) => {field.onChange(e.value); onInputChange(e, field.name);}} 
                            showIcon dateFormat="dd/mm/yy"/>
                        )} />
                        
                        {getFormErrorMessage(errors, 'issue_date')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="value" className={classNames({ 'p-error': errors.value })}>Valor *</label>
                        <InputNumber id="value" name="value" value={invoice.value ? invoice.value : null} mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                            {...register("value", {required: 'Valor é Obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'value')} className={classNames({ 'p-invalid': errors.value })} />
                        {getFormErrorMessage(errors, 'value')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="attachment" className={classNames({ 'p-error': errors.attachment })}>Danfe *</label>
                        <FileInput name="attachment" control={control} setValue={onInputChange} />
                        {getFormErrorMessage(errors, 'attachment')}
                    </div>
                </div>
                <Button type="submit" label="Salvar" icon="pi pi-save" className="p-button-primary"/>
            </form>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useController } from 'react-hook-form';

export const FileInput = ({control, name, setValue}) => {
    const { field } = useController({ control, name });

    return (
        <input
            type="file"
            className="p-fileupload-row"
            onChange={(e) => {
                setValue(e.target.value, name);
                field.onChange(e.target.files);
            }}
        />
    );
}

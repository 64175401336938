import api from './api';

export class UserService {
    async getUsers() {
        return api.get('users').then(res => res.data);
    }

    async getUserByID(id: string) {
        return api.get(`users/${id}`).then(res => res.data);
    }

    async createUser(data: object) {
        return api.post(`users`, data);
    }

    async updateUser(id: string, data: object) {
        return api.put(`users/${id}`, data);
    }

    async disableUser(id: string) {
        return api.post(`users/disable/${id}`);
    }
}
import api from './api';

export class IntegrationLogService {
    async getIntegrationLogs() {
        return api.get('integration/logs').then(res => res.data);
    }

    async getIntegrationLogByID(id: string) {
        return api.get(`integration/logs/${id}`).then(res => res.data);
    }

    async deleteIntegrationLogr(id: string) {
        return api.delete(`integration/logs/${id}`);
    }
}
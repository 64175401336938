import api from './api';

export class ProfileService {
    async getProfiles() {
        return api.get('profiles')
                    .then(res => res.data);
    }

    async getProfileByID(id: string) {
        return api.get(`profiles/${id}`)
                    .then(res => res.data);
    }

    async createProfile(data: object) {
        return api.post(`profiles`, data);
    }

    async updateProfile(id: string, data: object) {
        return api.put(`profiles/${id}`, data);
    }

    async disableProfile(id: string) {
        return api.post(`profiles/disable/${id}`)
                    .then(res => res.data);
    }
}
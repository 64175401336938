import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

import { AuthProvider, useAuth } from './contexts/Auth';
import { Login } from './pages/login/Login';
import Panel from './Panel';
import { ToastProvider } from './contexts/Toast';

export default function Router() {
  return (
    <AuthProvider>
      <ToastProvider>
        <BrowserRouter>
            <Switch>
              <Route exact path='/login'>
                <Login />
              </Route>
              <PrivateRoute path='/'>
                <Panel />
              </PrivateRoute>
            </Switch>
        </BrowserRouter>
      </ToastProvider>
    </AuthProvider>
  )
}

export function PrivateRoute({ children, ...rest }) {
  const { signed, user, token, loading } = useAuth();

  if (loading) {
    return (
      <div className="p-d-flex p-jc-center">
        <ProgressSpinner className="" />
    </div>
    );
  }
  
  return (
    <Route {...rest} render={({ location }) =>
      signed ? (children) :
        (<Redirect to={{ pathname: '/login', state: { from: location } }} />)
      }
    />
  )
}
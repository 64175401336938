import { Button } from 'primereact/button'

export const ProdutoDetails = ( { response }) => {
    const gridPro = response[ 'respostaPedido' ][0][ 'gridPro' ]

    if ( gridPro ) {
        let i;
        for ( i = 0; i < Object.keys( gridPro ).length; i++ ) {
            if ( gridPro[i].retorno != "OK" ) {
                return (
                    <div>
                        <Button icon="pi pi-times" className="p-button-danger p-button-text p-text-left" label={ gridPro[i].retorno } />
                    </div>
                )
            } else {
                return (
                    <div>
                        <Button icon="pi pi-check" className="p-button-success p-button-text p-text-left" label="Produtos integrados com sucesso." />
                    </div>
                )
            }
        }
    } else {
        return (
            <div>
                <Button icon="pi pi-exclamation-circle" className="p-button-secondary p-button-text p-text-left" label="Não há produtos para integrar." />
            </div>
        )
    }
}
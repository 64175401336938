import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { getFormErrorMessage } from '../../../../utilities/Utils';
import { ManualService } from '../../../../service/ManualService';
import { Manual } from '../../../../types/Manual';
import { useToast } from '../../../../contexts/Toast';
import { Dropdown } from 'primereact/dropdown';
import { FileInput } from '../../../../components/FileInput';
import { Product } from '../../../../types/Product';
import { ProductService } from '../../../../service/ProductService';

export const CreateEditManual = () => {
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [manual, setManual] = useState<Manual>({} as Manual);
    const [products, setProducts] = useState([] as Array<Product>);
    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);

    const manualService = new ManualService();
    const productService = new ProductService();

    useEffect(() => {
        async function loadData(){
            if(id && !manual.id){
                manualService.getManualByID(id).then(data => setManual(data));
            }
    
            if(products.length === 0)
                await productService.getProducts().then(data => setProducts(data));
        }
        
        loadData()
    }, [manual]);

    const { register, formState: { errors }, handleSubmit, control } = useForm();

    const onSubmit = async (data) => {
        setFormData(data);
        setShowMessage(true);

        if(!data.attachment){
            showError("Selecione um arquivo!")
        }

        console.log(manual)
        let result = null

        if(manual.id){
            result = await manualService.update(id, manual);
        }else{
            result = await manualService.create(manual);
        }
        
        if(result.status === 201 || result.status === 200){
            const fileResult = await manualService.attachment(result.data.id, data.attachment);

            if(fileResult.status === 204){
                showSuccess("Boleto salvo com sucesso.");
                history.push(`/client-portal/product/manual`);
            }else{
                showError("Nāo foi possivel salvar o manual.")
            }
        }else{
            showError("Nāo foi possivel salvar o manual.")
        }
    };

    const onInputChange = (e, name) => {
        let val = null;

        if(e.target){
            val = (typeof e.target.value === "object" && e.target.value.key) || e.target.value;
        }else if(e.value){
            val = e.value;
        }

        let _manual = { ...manual };
        _manual[`${name}`] = val;

        setManual(_manual);
    }

    return (
        <div>
            <h3>Cadastro/Edição de Manual</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="product_id" className={classNames({ 'p-error': errors.product_id })}>Produto *</label>
                        <Controller name="product_id" defaultValue={manual.product_id} control={control} rules={{ required: "Produto é obrigatório." }} render={({ field }) => (
                            <Dropdown value={field.value} options={products} optionValue="id" optionLabel="name" 
                                filter showClear filterBy="name" onChange={(e) => {field.onChange(e, 'product_id'); onInputChange(e, field.name);}} 
                                placeholder="Selecione um Produto" className={classNames({ 'p-invalid': errors.product_id })} autoFocus
                            />
                        )} />
                        {getFormErrorMessage(errors, 'product_id')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nome *</label>
                        <InputText id="name" name="name" defaultValue={manual.name} {...register("name", { required: 'Nome é obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'name')} className={classNames({ 'p-invalid': errors.name })} />
                        {getFormErrorMessage(errors, 'name')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="version" className={classNames({ 'p-error': errors.version })}>Versāo *</label>
                        <InputText id="version" name="version" defaultValue={manual.version} {...register("description", { required: 'Versāo é obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'version')} className={classNames({ 'p-invalid': errors.version })} />
                        {getFormErrorMessage(errors, 'version')}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>Descriçāo *</label>
                        <InputText id="description" name="description" defaultValue={manual.description} {...register("description", { required: 'Descriçāo é obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'description')} className={classNames({ 'p-invalid': errors.description })} />
                        {getFormErrorMessage(errors, 'description')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="attachment" className={classNames({ 'p-error': errors.attachment })}>Arquivo *</label>
                        <FileInput name="attachment" control={control} setValue={onInputChange} />
                        {getFormErrorMessage(errors, 'attachment')}
                    </div>
                </div>
                <Button type="submit" label="Salvar" icon="pi pi-save" className="p-button-primary"/>
            </form>
        </div>
    );
}

import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export const Delete = ({visible, setDialogVisible, deleteAdvisor, handleDelete}) => {
    const hideDialog = () => {
        setDialogVisible(false);
    }

    const onDeleteClick = async () => {
        handleDelete();
        hideDialog();
    }

    const deleteDialogFooter = (
        <>
            <Button type="button" label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button type="button" label="Remover" icon="pi pi-check" className="p-button-text" onClick={onDeleteClick} />
        </>
    );

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header="Remover" modal className="p-fluid" footer={deleteDialogFooter} onHide={hideDialog}>
            <div>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {deleteAdvisor && <span>{deleteAdvisor}</span>}
                </div>
            </div>
        </Dialog>
    );
}

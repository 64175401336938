import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom';

import { Delete } from '../../../../components/Delete';
import { useToast } from '../../../../contexts/Toast';
import { InvoicesService } from '../../../../service/InvoicesService';
import { Invoice } from '../../../../types/Invoice';

export const ListInvoices = () => {
    const dt = useRef(null);
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [invoices, setInvoices] = useState([]);
    const [invoice, setInvoice] =  useState({} as Invoice);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteInvoiceAdvisor, setDialogInvoiceAdvisor] = useState(null);

    const invoiceService = new InvoicesService();

    useEffect(() => {
        invoiceService.getInvoices().then(data => setInvoices(data));
    }, [invoice]);

    const handleDelete = async () => {
        const result = await invoiceService.delete(invoice.id);

        if(result.status === 204){
            showSuccess("Nota fiscal removida com sucesso.")
        }else{
            showError("Não foi possível remover a nota fiscal.")
        }

        setDialogInvoiceAdvisor(null);
        setInvoice({} as Invoice);
    }

    const deleteInvoice = (invoice) => {
        setInvoice(invoice);
        setDialogInvoiceAdvisor("Você deseja mesmo remover a Nota fiscal?");
        setDeleteDialog(true);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-mb-1">Gerenciamento de Notas Fiscais</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter((e.target as HTMLInputElement).value)} placeholder="Pesquisar..." />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => deleteInvoice(rowData)}/>
            </div>
        );
    }

    return (
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-3 p-lg-3">
                <Button onClick={() => history.push(`/client-portal/finance/invoice/create/`)} icon="pi pi-plus" label="Nova Nota Fiscal" />
            </div>
            <div className="p-col-12 p-lg-12">
                <DataTable ref={dt} value={invoices}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
                    globalFilter={globalFilter} emptyMessage="Nenhuma Nota Fiscal Encontrada." header={header}>
                    <Column field="serie" header="Nome"></Column>
                    <Column field="serie" header="Série"></Column>
                    <Column field="number" header="Número"></Column>
                    <Column field="issue_date" header="Data de Emissāo"></Column>
                    <Column field="value" header="Valor"></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

            <Delete
                visible={deleteDialog}
                setDialogVisible={setDeleteDialog}
                deleteAdvisor={deleteInvoiceAdvisor}
                handleDelete={handleDelete}
            />
        </div>
    );
}

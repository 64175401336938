import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom';

import { ClientService } from '../../../service/ClientService';
import { Client } from '../../../types/Client';

export const ListClient = () => {
    const dt = useRef(null);
    const history = useHistory();

    const [clients, setClients] = useState(Array<Client>());
    const [globalFilter, setGlobalFilter] = useState(null);

    const clientService = new ClientService();

    useEffect(() => {
        clientService.getClients().then(data => setClients(data));
    }, [setClients]);

    const header = (
        <div className="table-header">
            <h5 className="p-mb-1">Gerenciamento de Clientes</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter((e.target as HTMLInputElement).value)} placeholder="Pesquisar..." />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => history.push(`/register/client/edit/${rowData.id}`)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"/>
            </div>
        );
    }

    return (
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-3 p-lg-3">
                <Button onClick={() => history.push(`/register/client/create`)} icon="pi pi-plus" label="Novo Cliente" />
            </div>
            <div className="p-col-12 p-lg-12">
                <DataTable ref={dt} value={clients}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
                    globalFilter={globalFilter} emptyMessage="Nenhum Cliente Encontrado." header={header}>
                    <Column field="cnpj" header="CNPJ" sortable></Column>
                    <Column field="name" header="Nome" sortable></Column>
                    <Column field="email" header="E-mail" sortable></Column>
                    <Column field="phone" header="Telefone" ></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>
        </div>
    );
}

import api from './api';

export class BankSlipService {
    async getBankSlips() {
        return api.get('bank-slips')
                    .then(res => res.data);
    }

    async getBankSlipByID(id: string) {
        return api.get(`bank-slips/${id}`)
                    .then(res => res.data);
    }

    async create(data: object) {
        return api.post(`bank-slips`, data);
    }

    async update(id: string, data: object) {
        return api.post(`bank-slips/${id}`, data);
    }

    async delete(id: string) {
        return api.delete(`bank-slips/${id}`);
    }

    async attachment(id: string, file: FileList) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('attachment', file[0]);

        return api.patch(`bank-slips/${id}/attachment`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}
import { Badge } from 'primereact/badge';

export const getFormErrorMessage = (errors: any, name: string) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
};
export function statusBodyTemplate(rowData) {
    return <Badge value={rowData.isActive ? 'Ativo' : 'Inativo'} severity={rowData.isActive ? 'info' : 'danger'}></Badge>;
}

export function dateBodyTemplate(rowData, props) {
    const date = new Date(rowData[props.field]);
    return date.toLocaleDateString("pt-br");
}
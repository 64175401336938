import api from './api';

export class ManualService {
    async getManuals() {
        return api.get('manuals')
                    .then(res => res.data);
    }

    async getManualByID(id: string) {
        return api.get(`manuals/${id}`)
                    .then(res => res.data);
    }

    async create(data: object) {
        return api.post(`manuals`, data);
    }

    async update(id: string, data: object) {
        return api.post(`manuals/${id}`, data);
    }

    async delete(id: string) {
        return api.delete(`manuals/${id}`);
    }

    async attachment(id: string, file: FileList) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('attachment', file[0]);

        return api.patch(`manuals/${id}/attachment`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}
import { Contact } from '../types/Contact';
import api from './api';

export class ContactService {
    async getClients() {
        return api.get('contacts')
                    .then(res => res.data);
    }

    async getClientByID(id: string) {
        return api.get(`contacts/${id}`)
                    .then(res => res.data);
    }

    async create(data: Contact) {
        return api.post(`contacts`, data);
    }

    async update(id: string, data: Contact) {
        return api.post(`contacts/${id}`, data);
    }

    async disable(id: string) {
        return api.post(`contacts/disable/${id}`)
                    .then(res => res.data);
    }
}
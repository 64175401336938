import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { ParcelaDetails } from './ParcelaDetails';
import { ProdutoDetails } from './ProdutoDetails';
import { ServicoDetails } from './ServicoDetails';
import { Panel } from 'primereact/panel';
import { LogDetailsHeader } from './LogDetailsHeader'
import { useEffect, useState } from 'react';

export const ViewLogDetails = ( { visible, setDialogVisible, data } ) => {

    const hideDialog = () => {
        setDialogVisible(false);
    }

    let response = {}
    let request = {}
    let cnv_request = {}
    let integrationId = {}
    let creation

    if ( data[ 'id' ] ) {
        response =  JSON.parse( data[ 'response' ] )
        request = JSON.parse( data[ 'request' ] )
        cnv_request = JSON.parse( data[ 'cnv_request' ] )
        integrationId = JSON.parse( data[ 'integration_id' ] )
        creation = data[ 'created_at' ]

        if ( request[ 'description' ] == "None" ) {
            request[ 'description' ] = "Sem descrição."
        } 
    }
    

    const [ activeIndex, setActiveIndex ] = useState( null );

    const onClick = ( itemIndex ) => {
        let _activeIndex = activeIndex ? [ ...activeIndex ] : [];

        if ( _activeIndex.length === 0 ) {
            _activeIndex.push( itemIndex );
        }
        else {
            const index = _activeIndex.indexOf( itemIndex );
            if (index === -1) {
                _activeIndex.push( itemIndex );
            }
            else {
                _activeIndex.splice( index, 1 );
            }
        }

        setActiveIndex(_activeIndex);
    }

    const leadDialogFooter = (
        <>
            <Button type="button" label="Fechar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={ hideDialog } />
        </>
    );

    return (
        <Dialog visible={ visible } style={{ width: '80em' }} header="Detalhes do log" modal className="p-fluid" footer={ leadDialogFooter } onHide={ hideDialog } dismissableMask closable={ false }>
            

            <div className="p-d-flex p-jc-between">
                <div>
                    <div className="p-text-bold"><h3>{ request[ 'title' ] }</h3></div>
                </div>
                <div>
                    { creation }
                </div>
            </div>

            <div className="p-text-left">{ request[ 'description' ] }</div>
            <Divider />

            <LogDetailsHeader
                request = { request }
            />

            <div className="p-grid p-formgrid p-fluid p-pt-2">
                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                    <Panel header="Integração de Parcelas">
                        <ParcelaDetails
                            response={ response }
                        />
                    </Panel>
                </div>
                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                    <Panel header="Integração de Produtos">
                        <ProdutoDetails
                            response={ response }
                        />
                    </Panel>
                </div>
                <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                    <Panel header="Integração de Serviços">
                        <ServicoDetails
                            response={ response }
                        />
                    </Panel>
                </div>
            </div>

            <Divider />
            <Accordion>
                <AccordionTab header="Solicitação do Piperun">
                    { JSON.stringify( request, null, 2 ) }
                </AccordionTab>
                <AccordionTab header="Dados enviados à Sênior">
                    { JSON.stringify( cnv_request, null, 2 ) }
                </AccordionTab>
                <AccordionTab header="Retorno da Sênior">
                    { JSON.stringify( response, null, 2 ) }
                </AccordionTab>
            </Accordion>
        </Dialog>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom';

import { UserService } from '../../../service/UserService';
import { statusBodyTemplate } from '../../../utilities/Utils';

export const ListUser = () => {
    const dt = useRef(null);
    const history = useHistory();

    const [users, setUsers] = useState([]);
    const [departaments, setDepartaments] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);

    useEffect(() => {
        const userService = new UserService();
        userService.getUsers().then(data => Array.isArray(data) ?  setUsers(data) : setUsers([data]));
        setDepartaments([{id: 1, name: 'Vendas'}, {id: 2, name: "Manutenção"}]);
    }, [setUsers]);

    const header = (
        <div className="table-header">
            <h5 className="p-mb-1">Gerenciamento de Usuários</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter((e.target as HTMLInputElement).value)} placeholder="Pesquisar..." />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => history.push(`/register/user/edit/${rowData.id}`)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"/>
            </div>
        );
    }

    return (
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-2 p-lg-2">
                <Button onClick={() => history.push(`/register/user/create/`)} icon="pi pi-plus" label="Novo Usuário" />
            </div>
            <div className="p-col-12 p-lg-12">
                <DataTable ref={dt} value={users}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
                    globalFilter={globalFilter} emptyMessage="Nenhum Usuário Encontrado." header={header}>
                    <Column field="name" header="Usuário" sortable></Column>
                    <Column field="email" header="E-mail" sortable></Column>
                    <Column field="isActive" header="Status" body={statusBodyTemplate}></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>
        </div>
    );
}

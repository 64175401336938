import { Button } from 'primereact/button'

export const ServicoDetails = ( { response }) => {
    const gridSer = response[ 'respostaPedido' ][0][ 'gridSer' ]

    if ( gridSer ) {

        let i;
        for ( i = 0; i < Object.keys( gridSer ).length; i++ ) {
            if ( gridSer[i].retorno != "OK" ) {
                return (
                    <div>
                        <Button icon="pi pi-times" className="p-button-danger p-button-text p-text-left" label={ gridSer[i].retorno } />
                    </div>
                )
            } else {
                return (
                    <div>
                        <Button icon="pi pi-check" className="p-button-success p-button-text p-text-left" label="Serviços integrados com sucesso." />
                    </div>
                )
            }
        }
    } else {
        return (
            <div>
                <Button icon="pi pi-exclamation-circle" className="p-button-secondary p-button-text p-text-left" label="Não há serviços para integrar." />
            </div>
        )
    }
}
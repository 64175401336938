export const LogDetailsHeader = ( { request }) => {

    return (
        <div className="p-grid p-formgrid p-fluid p-py-2">
            <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                <span className="p-text-bold">CLIENTE</span><br />
                <span className="p-text-bold">Nome: </span>{ request[ 'company' ].company_name }<br />
                <span className="p-text-bold">CNPJ: </span>{ request[ 'company' ].cnpj }
            </div>
            <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                <span className="p-text-bold">PAGAMENTO</span><br />
                <span className="p-text-bold">Total: </span>R$ { request[ 'value' ] }
            </div>
            <div className="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
                <span className="p-text-bold">RESPONSÁVEL</span><br />
                <span className="p-text-bold">Nome: </span>{ request[ 'user' ].name }
            </div>
        </div>
    )
}
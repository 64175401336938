import React, { useState, useCallback } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Card } from 'primereact/card';
import { useAuth } from '../../contexts/Auth';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { getFormErrorMessage } from '../../utilities/Utils';

export const Login = () => {
    const auth = useAuth();
    const history = useHistory();

    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);

    const { control, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = useCallback(async (data: any) => {
        setFormData(data);

        const authResult = await auth.signIn(data);

        if(authResult){
            history.push("/");
        }else{
            setShowMessage(true);
        }
    }, []);

    const header = <img alt="Logo" src='assets/layout/images/logo-sigamaq.png'/>;
    
    return (
        <div className="p-d-flex p-justify-center" style={{minHeight: '700px'}}>
            <div className="p-as-center">
                <Card title="Faça seu Login" style={{ width: '25rem', marginBottom: '2em' }} header={header}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-fluid">
                            <div className="p-field p-col">
                                <label htmlFor="username" className={classNames({ 'p-error': errors.username })}>Usuário ou E-mail</label>
                                <Controller name="username" defaultValue="" control={control} rules={{ required: 'Usuário é obrigatório.' }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage(errors, 'username')}
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="p-field p-col">
                                <label htmlFor="password">Senha</label>
                                <Controller name="password" defaultValue="" control={control} rules={{ required: 'Senha é obrigatório.' }} render={({ field, fieldState }) => (
                                    <Password id={field.name} {...field} toggleMask={true} feedback={false} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage(errors, 'password')}
                            </div>
                        </div>
                        <div className="p-fluid">
                            <Button type="submit" label="Entrar" icon="pi pi-sign-in" className="p-button-md"/>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );
}
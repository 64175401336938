import api from './api';

export class AuthenticationService {
    async auth(credentials: object) {
        return api.post('sessions', credentials)
                    .then(res => res.data);
    }

    async registerToken() {
        return api.get('sessions')
                    .then(res => res.data);
    }
}